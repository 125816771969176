<template>
<div class="grid">
  <div class="col-12">
    <div class="card">
      <Toast />
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button label="Nova Divida Ativa" icon="pi pi-plus" class="p-button-success mr-2" @click="openNewDivida" />
            <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
              :disabled="!selectedProducts || !selectedProducts.length" />
          </div>
        </template>

        <template v-slot:end>
          <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
          <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
        </template>
      </Toolbar>

      <DataTable exportFilename="relatorio_iptu[GUIAS]" csvSeparator=";" ref="dt" :value="faturas"
        v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
        responsiveLayout="scroll">
        <template #header>
          <div class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              ">
            <h5 class="m-0">Gereneciar Guias</h5>
            <span class="flex flex-row align-items-center mt-2 md:mt-0 p-input-icon-left gap-3">
              <i class="pi pi-search" />
              <Dropdown v-model="filters['id_empresa'].value" :options="empresas" showClear optionLabel="label"
                optionValue="value" placeholder="Selecione uma empresa">
              </Dropdown>
              <InputText v-model="filters['global'].value" placeholder="Buscar" />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="id" header="ID" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Id</span>
            {{ slotProps.data.id }}
          </template>
        </Column>

        <Column field="razao_social" header="Empresa" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">razao_social</span>
            {{ slotProps.data.razao_social }}
          </template>
        </Column>
        <Column field="inscricao_imobiliaria" header="Inscrição Imobiliaria" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">inscricao_imobiliaria</span>
            {{ slotProps.data.inscricao_imobiliaria }}
          </template>
        </Column>
        <Column field="ordem_sap" header="Ordem SAP" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title"></span>
            {{ slotProps.data.ordem_sap }}
          </template>
        </Column>

        <Column field="data_vencimento_original" header="Data Vencimento Original" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title"></span>
            {{ slotProps.data.data_vencimento_original }}
          </template>
        </Column>

        <Column>
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
              @click="editProduct(slotProps.data)" />

            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
              @click="apagarFaturaModal(slotProps.data)" />
            <!-- <Button
                class="p-button p-button-info ml-2"
                @click="enviarNovamente(slotProps.data)"
              >
                Reenviar
              </Button> -->
          </template>
        </Column>
      </DataTable>


      <Dialog v-model:visible="dividaDialog" :style="{ width: '1400px' }" header="Gerenciar Dívida" :modal="true"
        class="p-fluid">
        <div class="grid">
          <div class="col-6">
            <div class="field">
              <label for="razao_social">Empresa</label>
              <Dropdown id="status" v-model="id_empresa" :options="empresas" optionLabel="label" optionValue="value"
                placeholder="Selecione uma empresa" :class="{ 'p-invalid': submitted && !id_empresa }" required="true"
                :filter="true">
              </Dropdown>
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="razao_social">Unidade</label>
              <Dropdown id="status" v-model="id_unidade" :options="unidades" optionLabel="label" optionValue="value"
                placeholder="Selecione uma unidade (Ordem SAP)" :class="{ 'p-invalid': submitted && !id_unidade }"
                required="true" :filter="true">
              </Dropdown>
            </div>
          </div>

          <div class="col-3">
            <div class="field">
              <label for="data_vencimento">Vencimento Original</label>
              <InputMask id="data_vencimento_original" v-model="data_vencimento_original" mask="99/99/9999" />
            </div>
          </div>
          <div class="grid" v-for="(lista, index) in listas" :key="index">
            <div class="col-1" style="width:11%">
              <div class="field">
                <label for="data_referencia">Ano de Exercício</label>
                <Dropdown v-model="lista.data_exercicio" :options="anos" optionLabel="label" optionValue="value"
                  placeholder="Ano de Exercício">
                </Dropdown>
              </div>
            </div>
            <div class="col-1" style="width:11%">
              <div class="field">
                <label for="data_vencimento">Vencimento</label>
                <InputMask id="data_vencimento" v-model.trim="lista.data_vencimento" mask="99/99/9999" />
              </div>
            </div>
            <div class="col-1" style="width:11%">
              <div class="field">
                <label for="data_vencimento">Emissão </label>
                <InputMask id="data_emissao" v-model.trim="lista.data_emissao" mask="99/99/9999" />
              </div>
            </div>
            <div class="col-1">
              <div class="field">
                <label for="valor">Valor</label>
                <InputNumber mode="currency" currency="BRL" locale="pt-BR" id="valor" v-model="lista.valor" autofocus />
              </div>
            </div>
            <div class="col-1">
              <div class="field">
                <label for="correcao">Correção</label>
                <InputNumber mode="currency" currency="BRL" locale="pt-BR" id="correcao" v-model="lista.correcao"
                  autofocus />
              </div>
            </div>
            <div class="col-1">
              <div class="field">
                <label for="juros">Juros</label>
                <InputNumber mode="currency" currency="BRL" locale="pt-BR" id="juros" v-model="lista.juros" autofocus />
              </div>
            </div>
            <div class="col-1">
              <div class="field">
                <label for="multa">Multa</label>
                <InputNumber mode="currency" currency="BRL" locale="pt-BR" id="multa" v-model="lista.multa" autofocus />
              </div>
            </div>
            <div class="col-1">
              <div class="field">
                <label for="desconto">Desconto</label>
                <InputNumber mode="currency" currency="BRL" locale="pt-BR" id="desconto" v-model="lista.desconto"
                  autofocus />
              </div>
            </div>
            <div class="col-1">
              <div class="field">
                <label for="desconto">Encargo</label>
                <InputNumber mode="currency" currency="BRL" locale="pt-BR" id="encargo" v-model="lista.encargo"
                  autofocus />
              </div>
            </div>
            <div class="col-1">
              <div class="field">
                <label for="total">Total</label>
                <InputNumber mode="currency" currency="BRL" locale="pt-BR" id="total" v-model="lista.total" autofocus />
              </div>
            </div>
            <div class="col-1">
              <div class="field">
                <label for="total" style="display: block">&nbsp;</label>
                <Button icon="pi pi-plus" class="p-button-secondary p-button p-sm" @click="addLinha()" />
                <Button icon="pi pi-minus" class="p-button-danger ml-1 p-button p-sm" @click="removeLinha()" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="field">
              <label for="boleto">Boleto/Nº documento/NºDAM/Nota fiscal/Nosso Número</label>
              <InputText id="boleto" v-model="boleto" required="true" autofocus
                :class="{ 'p-invalid': submitted && !boleto }" />
            </div>
          </div>
          <div class="col-4">
            <div class="field">
              <label for="boleto">Código de Barras</label>
              <InputText id="codigo_barras" v-model="codigo_barras" required="true" autofocus
                :class="{ 'p-invalid': submitted && !codigo_barras }" />
            </div>
          </div>
          <div class="col-2">
            <div class="field">
              <label for="data_vencimento">Valor Total Fatura</label>
              <InputNumber mode="currency" currency="BRL" locale="pt-BR" id="valor_total_fatura"
                v-model="valor_total_fatura" autofocus />

            </div>
          </div>

          <div class="col-1">
            <div class="field">
              <label for="contrato_re">Arquivo</label>
              <FileUpload chooseLabel="Arquivo" ref="arquivo" mode="basic" name="arquivo"
                v-on:change="onChangeFileUpload()" />
            </div>
          </div>
          <div class="col-2" v-if="this.url">
            <div class="field">
              <label for="contrato_re">Guia</label>
              <Button label="Ver Guia" icon="pi pi-download" class="p-button-secondary p-button"
                @click="verArquivo()" />
            </div>
          </div>
        </div>

        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
          <Button label="Salvar" icon="pi pi-check" id="salvar" class="p-button-success p-button-sm"
            @click="salvarFatura" />
        </template>
      </Dialog>

      <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true" header="&nbsp;">
        <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="unidade">Apagar: <b>{{ inscricao_imobiliaria }}</b>?</span>
        </div>
        <template #footer>
          <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
            @click="deleteProductDialog = false" />
          <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarFatura" />
        </template>
      </Dialog>

      <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
        <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="unidades">Apagar faturas selecionadas?</span>
        </div>
        <template #footer>
          <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
          <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
        </template>
      </Dialog>
    </div>
  </div>
</div>
</template>

<script>
import { FilterMatchMode } from "primevue/api"

export default {
  data() {
    return {
      anos: [
        { label: "2017", value: 2017 },
        { label: "2018", value: 2018 },
        { label: "2019", value: 2019 },
        { label: "2020", value: 2020 },
        { label: "2021", value: 2021 },
        { label: "2022", value: 2022 },
        { label: "2023", value: 2023 },

      ],
      enviar: "sim",
      observacao: "",
      cnpj: "",
      razao_social: "",
      enviar_por_email: [
        { label: "Sim", value: "sim" },
        { label: "Não", value: "nao" },
      ],
      parcelas: [
        { label: "Única", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
      ],
      dados: [],
      inputFields: [1],
      dividaDialog: false,
      id: "",
      arquivo: "",
      unidades: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      unidade: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      data_exercicio: "",
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      id_empresa: "",
      id_unidade: "",
      parcela: "",
      id_tipo: "",
      data_referencia: "",
      data_vencimento: "",
      data_vencimento_original: "",
      valor_total_fatura: "",
      data_emissao: "",
      total: "",
      boleto: "",
      faturas: [],
      url: "",
      total_desconto: "",
      listas: [
        {
          data_exercicio: "",
          data_vencimento: "",
          data_emissao: "",
          valor: 0,
          correcao: 0,
          juros: 0,
          multa: 0,
          desconto: 0,
          encargo: 0,
          total: 0

        }
      ]
    }
  },
  productService: null,
  created() {
    this.initFilters()
  },
  mounted() {
    this.listarEmpresa()
    this.listarAreas()
    this.listarUnidades()
    this.listarTipos()
    this.listarFatura()
    //this.productService.getProducts().then((data) => (this.products = data));
  },
  methods: {
    listarAreas() {
      this.axios
        .post("/areas/listar")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] }
            options.push(option)
          })
          this.areas = options
        })
        .catch((error) => {
          console.log(error)
        })
    },
    listarUnidades() {
      this.axios
        .post("/unidades/listar")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            let option = {
              value: el["id"],
              label: "Ord. Sap: " + el["ordem_sap"] + " - Insc. Imob: " + el["inscricao_imobiliaria"] + " - Cont. Re: " + el["controle_re"],
            }
            options.push(option)
          })
          this.unidades = options
        })
        .catch((error) => {
          console.log(error)
        })
    },
    trocarCidades() {
      console.log(this.unidade.id_estado)
      this.listarCidades(this.unidade.id_estado)
    },

    listarTipos() {
      this.axios
        .post("/tipos-faturas/listar")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] }
            options.push(option)
          })
          this.tipos = options
        })
        .catch((error) => {
          console.log(error)
        })
    },
    listarEmpresa() {
      this.axios
        .post("/empresas/listar")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            options.push({ value: el["id"], label: el["razao_social"] })
          })
          this.empresas = options
        })
        .catch((error) => {
          console.error(error)
        })
    },
    listarFatura() {
      this.axios
        .post("/dividas/listar")
        .then((response) => {
          this.faturas = response.data.dados
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          })
        })
    },
    trocarStatus() {
      this.unidade.status = this.status.value
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      return
    },
    openNew() {
      this.id = ""
      this.id_empresa = ""
      this.id_unidade = ""
      this.id_tipo = ""
      this.data_referencia = ""
      this.data_vencimento = ""
      this.data_emissao = ""
      this.total = ""
      this.total_desconto = ""
      this.boleto = ""
      this.codigo_barras = ""
      this.parcela = ""
      this.observacao = ""
      this.cnpj = ""
      this.razao_social = ""
      this.enviar = ""
      this.url = ""
      this.data_vencimento_original = ""
      this.valor_total_fatura = ""
      this.submitted = false
      this.productDialog = true
    },
    openNewDivida() {
      this.id = ""
      this.id_empresa = ""
      this.id_unidade = ""
      this.id_tipo = ""
      this.data_referencia = ""
      this.data_vencimento = ""
      this.data_emissao = ""
      this.total = ""
      this.total_desconto = ""
      this.boleto = ""
      this.codigo_barras = ""
      this.parcela = ""
      this.data_vencimento_original = ""
      this.url = ""
      this.valor_total_fatura = ""
      this.submitted = false
      this.dividaDialog = true

      this.listas = [
        {
          data_exercicio: "",
          data_vencimento: "",
          data_emissao: "",
          valor: 0,
          correcao: 0,
          juros: 0,
          multa: 0,
          encargo: 0,
          desconto: 0,
          total: 0

        }
      ]
    },
    hideDialog() {
      this.dividaDialog = false
      this.submitted = false
    },
    onChangeFileUpload() {
      this.arquivo = this.$refs.arquivo.files[0]
    },
    salvarFatura() {
      let bt = document.getElementById("salvar")
      bt.innerHTML =
        '<span class="pi pi-check p-button-icon p-button-icon-left"></span> <span class="p-button-label">Aguarde</span>'
      bt.disabled = true
      this.submitted = true

      let formData = new FormData()
      formData.append("arquivo", this.arquivo)

      let dados = {
        id: this.id,
        id_empresa: this.id_empresa,
        id_unidade: this.id_unidade,
        id_tipo: this.id_tipo,
        data_referencia: this.data_referencia,
        data_vencimento: this.data_vencimento,
        data_vencimento_original: this.data_vencimento_original,
        data_emissao: this.data_emissao,
        data_exercicio: this.data_exercicio,
        total: this.total,
        total_desconto: this.total_desconto,
        boleto: this.boleto,
        codigo_barras: this.codigo_barras,
        parcela: this.parcela,
        observacao: this.observacao,
        cnpj: this.cnpj,
        razao_social: this.razao_social,
        valor_total_fatura: this.valor_total_fatura,
        enviar: this.enviar,
        listas: this.listas

      }

      formData.append("dados", JSON.stringify(dados))


      if (this.id) {
        this.axios
          .post("/dividas/editar", formData)
          .then(() => {
            this.listarFatura()
            this.dividaDialog = false
            this.unidade = {}
            bt.innerHTML =
              '<span class="pi pi-check p-button-icon p-button-icon-left"></span> <span class="p-button-label">Salvar</span>'
            bt.disabled = false
          })
          .catch(() => {
            bt.innerHTML =
              '<span class="pi pi-check p-button-icon p-button-icon-left"></span> <span class="p-button-label">Salvar</span>'
            bt.disabled = false
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            })
          })
      } else {
        this.axios
          .post("/dividas/adicionar", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.listarFatura()
            this.dividaDialog = false
            this.unidade = {}
            bt.innerHTML =
              '<span class="pi pi-check p-button-icon p-button-icon-left"></span> <span class="p-button-label">Salvar</span>'
            bt.disabled = false
          })
          .catch(() => {
            bt.innerHTML =
              '<span class="pi pi-check p-button-icon p-button-icon-left"></span> <span class="p-button-label">Salvar</span>'
            bt.disabled = false
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Precisar preencher todos os campos!",
              life: 3000,
            })
          })
      }
    },
    editProduct(fatura) {
      this.dividaDialog = true
      this.fatura = { ...fatura }
      this.id = this.fatura.id
      this.id_empresa = this.fatura.id_empresa
      this.id_unidade = this.fatura.id_unidade
      this.id_tipo = this.fatura.id_tipo
      this.data_referencia = this.fatura.data_referencia
      this.data_vencimento = this.fatura.data_vencimento
      this.data_emissao = this.fatura.data_emissao
      this.total = this.fatura.total
      this.total_desconto = this.fatura.total_desconto
      this.boleto = this.fatura.boleto
      this.codigo_barras = this.fatura.codigo_barras
      this.parcela = this.fatura.parcela
      this.url = this.fatura.arquivo
      this.observacao = this.fatura.observacao
      this.cnpj = this.fatura.cnpj
      this.razao_social = this.fatura.razao_social
      this.enviar = this.fatura.enviar
      this.valor_total_fatura = this.fatura.valor_total_fatura
      this.data_vencimento_original = this.fatura.data_vencimento_original
      this.listarValores(this.id)
    },
    listarValores(id) {
      let dados = {
        id: id
      }
      this.axios
        .post("/dividas/valores", dados)
        .then((response) => {
          if (response.data.dados != "") {
            this.listas = response.data.dados
          }
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          })
        })
    },
    verArquivo() {
      window.open(this.url)
    },
    apagarFaturaModal(fatura) {
      this.fatura = { ...fatura }
      this.deleteProductDialog = true
    },
    apagarFatura() {
      this.axios
        .post("/dividas/apagar", this.fatura)
        .then(() => {
          this.listarFatura()
          this.deleteProductDialog = false
          this.unidade = {}
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Fatura apagada com sucsso!",
            life: 3000,
          })
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          })
        })
    },
    findIndexById(id) {
      let index = -1
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i
          break
        }
      }
      return index
    },
    createId() {
      let id = ""
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return id
    },
    exportCSV() {
      this.$refs.dt.exportCSV()
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true
    },

    addLinha() {
      this.listas.push({ value: 1 })
    },

    removeLinha() {
      this.listas.pop()

    },
    deleteSelectedProducts() {
      let obj = this.selectedProducts
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/dividas/apagar", obj[key])
          .then(() => {
            this.listarFatura()
            this.deleteProductDialog = false
            this.unidade = {}
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            })
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            })
          })
      })

      this.deleteProductsDialog = false
      this.selectedProducts = null
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Faturas apagada com sucesso!",
        life: 3000,
      })
    },
    initFilters() {
      this.filters = {
        id_empresa: { value: null, matchMode: FilterMatchMode.EQUALS },
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
@use "../../assets/demo/badges.scss";
</style>
